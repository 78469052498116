<template>
<div>
    <div class="vertical-wrapper pt-lg--10 pt-5 pb-lg--10 pb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-11 text-center default-page">
                    <div class="card border-0 text-center d-block">
                        <img :src="'/images/verify.png'" alt="icon" class="w200 mb-4 ml-auto mr-auto ">
                        <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Verify your email address</h1>
                        <p class="text-grey-500 font-xss">You've entered <b>{{email}}</b> as the email address for your account</p>
                        <p class="text-grey-500 font-xss">Please verify this email by checking your inbox</p>
                        <p class="text-grey-500 font-xss">Click <a href="javascript:void(0)" @click="onLogout()" class="text-danger fw-700">Logout</a> if u want to out from verification</p>
                        <button @click="getUser()"  class="p-3 w175 btn-current d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Refresh</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
        name: "Verification",
        data() {
            return {
                name: 'Loading',
                email: '',
                verification_at: null
            }
        },
        created(){
            this.getUser()
            this.getVerification()
        },
        methods: {
            async onLogout() {
                await axios.get(`${process.env.VUE_APP_URL_API}/logout`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    this.$router.push({name: 'BrowseNew'})
                }).catch((err) => console.error(err))
            },
            getUser() {
                axios.get(`${process.env.VUE_APP_URL_API}/user`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
                }).then(res => {
                    this.name = res.data.name
                    this.email = res.data.email
                    if(res.data.email_verified_at !== null) {
                        this.$router.push({ name: 'Home' })
                    }
                }).catch(() => {
                    this.$swal({
                        toast: true,
                        title: 'Authentication !',
                        text: 'Error Get User',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                })
            },
            getVerification() {
                axios.get(`${process.env.VUE_APP_URL_API}/email/send`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
                }).then(res => {
                    this.$swal({
                        toast: true,
                        title: 'Verification Success !',
                        text: res.data.msg,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }).catch((err) => {
                    this.$swal({
                        toast: true,
                        title: 'Verification Failed !',
                        text: err.response.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                })
            },
        }       
    }
</script>
